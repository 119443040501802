<!-- 我的 -->

<template>
  <div class="mine">
    <div class="content">
      <div class="my-info">
        <div class="info-box">
          <!-- <div class="avatar">
            <img src="../assets/images/avatar.png" alt="" />
          </div> -->
          <van-image
            width="0.8rem"
            height="0.8rem"
            fit="cover"
            round
            :src="systemConfig.appLogo"
          />
          <div class="avatar-right" style="margin-left: 5px;">
            <div class="name font-m f32">{{ userInfo.user.address | capitalize }}</div>
            <div class="level border-gradient" v-if="userInfo.user.authorizedStatus === 'success'">
              <img src="../assets/images/VIP5.png" alt="" />
              <div class="word theme-word-color">{{ $i18n.t("mine_senior_members") }}</div>
            </div>
            <div class="level" v-else>
              <img src="../assets/images/no-vip.png" alt="" />
              <div class="word">{{ $i18n.t("mine_ordinary_members") }}</div>
            </div>
          </div>
        </div>
        <div class="to-upgrade" @click="$router.push('/login?from=mine')" v-if="userInfo.user.authorizedStatus !== 'success'">
          <div class="word f24">To upgrade</div>
          <img src="../assets/images/icon-arraw-right1.svg" alt="" />
        </div>
      </div>
      <div class="my-level">
        <div class="level-item f28">
          <div class="word f color-666">{{ $i18n.t("my_credit_rating") }}</div>
          <div class="value font-b font-bold color-word">{{ $i18n.t("my_ordinary") }}</div>
        </div>
        <div class="level-item f28">
          <div class="word color-666">{{ $i18n.t("my_credit_grade") }}</div>
          <div class="value font-b font-bold color-333">{{ userInfo.score }}</div>
        </div>
      </div>
      <div class="money-block">
        <div class="mine-block">
          <div class="title f36 font-m color-333 font-bold">{{ $i18n.t("my_eth_balance") }}</div>
          <div class="data-block">
            <div class="data-item">
              <div class="word">{{ $i18n.t("my_authorization_limit") }}</div>
              <div class="price">
                <img src="../assets/images/icon-eth-s.svg" alt="" />
                <div class="num">{{ userInfo.approvedETH | substr6 }}</div>
              </div>
            </div>
            <div class="data-item">
              <div class="word">{{ $i18n.t("my_Freeze_limit") }}</div>
              <div class="price">
                <img src="../assets/images/icon-eth-s.svg" alt="" />
                <div class="num">{{ userInfo.calcFrozenEth }}</div>
              </div>
            </div>
            <div class="data-item">
              <div class="word">{{ $i18n.t("my_Available_limit") }}</div>
              <div class="price">
                <img src="../assets/images/icon-eth-s.svg" alt="" />
                <div class="num">{{ userInfo.availableETH | substr6 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mine-block">
          <div class="title f36 font-m color-333 font-bold">
            {{ $i18n.t("my_platform_USDC_balance") }}
          </div>
          <div class="data-block">
            <div class="data-item">
              <div class="word">{{ $i18n.t("my_Accumulated_USDC") }}</div>
              <div class="price">
                <img src="../assets/images/usdc.svg" alt="" />
                <div class="num">{{ userInfo.returnsUSD | substr6 }}</div>
              </div>
            </div>
            <div class="data-item">
              <div class="word">{{ $i18n.t("my_Frozen") }}</div>
              <div class="price">
                <img src="../assets/images/usdc.svg" alt="" />
                <div class="num">
                  {{ userInfo.frozenReturnsUSD | substr6 }}
                </div>
              </div>
            </div>
            <div class="data-item">
              <div class="word">{{ $i18n.t("my_Available_USDC") }}</div>
              <div class="price">
                <img src="../assets/images/usdc.svg" alt="" />
                <div class="num">{{ userInfo.withdrawableUSD | substr6 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mine-block">
          <div class="title f36 font-m color-333 font-bold">{{ $i18n.t("my_ETH_limit") }}</div>
          <div class="data-block">
            <div class="data-item">
              <div class="word">{{ $i18n.t("my_cumulative_gain") }}</div>
              <div class="price">
                <img src="../assets/images/icon-eth-s.svg" alt="" />
                <!-- <div class="num">{{ userInfo.returnsETH | fixed_6 }}</div> -->
                <div class="num">{{ userInfo.totalReturnsETH | substr6 }}</div>
              </div>
            </div>
            <div class="data-item">
              <div class="word">{{ $i18n.t("my_Today's_earnings") }}</div>
              <div class="price">
                <img src="../assets/images/icon-eth-s.svg" alt="" />
                <div class="num">
                  {{ _fixed(userInfo.todayReturnsETH) }}
                </div>
              </div>
            </div>
            <div class="data-item">
              <div class="word">{{ $i18n.t("my_Convertible_ETH") }}</div>
              <div class="price">
                <img src="../assets/images/icon-eth-s.svg" alt="" />
                <div class="num">{{ userInfo.withdrawableETH | substr6 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order-block mine-block">
        <div class="title f36 color-333" @click="toOrder('PENDING')">
          <div class="word font-bold">{{ $i18n.t("my_Order_Record") }}</div>
          <img src="../assets/images/icon-arraw-right.svg" alt="" />
        </div>
        <!-- //CONFIRMING|PENDING|FINISHED|CANNELLED -->
        <div class="data-block">
          <div class="data-item" @click="toOrder('CONFIRMING')">
            <div class="word">{{ $i18n.t("my_in_progress") }}</div>
            <div class="price">
              <div class="num">{{ orderCount.confirming }}</div>
            </div>
          </div>
          <div class="data-item" @click="toOrder('PENDING')">
            <div class="word">{{ $i18n.t("rd_in_task") }}</div>
            <div class="price">
              <div class="num">{{ orderCount.pending }}</div>
            </div>
          </div>
          <div class="data-item" @click="toOrder('FINISHED')">
            <div class="word">{{ $i18n.t("rd_Completed") }}</div>
            <div class="price">
              <div class="num">{{ orderCount.finished }}</div>
            </div>
          </div>
          <div class="data-item" @click="toOrder('CANNELLED')">
            <div class="word">{{ $i18n.t("my_Failed") }}</div>
            <div class="price">
              <div class="num">{{ orderCount.failed }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="enter-block">
        <div class="mine-block">
          <div class="title f36 font-m color-333">
            <div class="word font-bold">{{ $i18n.t("my_Common_functions") }}</div>
          </div>
          <div class="enter-list clearfix">
            <!-- <div class="enter-item fl">
              <a href="#/wallet" class="link">
                <img src="../assets/images/enter9.png" alt="" />
                <div class="word">{{ $i18n.t("my_my_wallet") }}</div>
              </a>
            </div>
            <div class="enter-item fl">
              <a href="#/recharge" class="link">
                <img src="../assets/images/enter1.png" alt="" />
                <div class="word">{{ $i18n.t("my_Recharge") }}</div>
              </a>
            </div> -->
            <div class="enter-item fl">
              <router-link class="link" to="/exchange">
                <img src="../assets/images/enter2.png" alt="" />
                <div class="word">{{ $i18n.t("my_exchange") }}</div>
              </router-link>
            </div>
            <div class="enter-item fl">
              <router-link class="link" to="/withdraw">
                <img src="../assets/images/enter3.png" alt="" />
                <div class="word">{{ $i18n.t("my_Withdrawal") }}</div>
              </router-link>
            </div>
            <div class="enter-item fl">
              <router-link class="link" to="/loan">
                <img src="../assets/images/enter10.png" alt="" />
                <div class="word">{{ $i18n.t("my_Loan_Center") }}</div>
              </router-link>
            </div>
            <div class="enter-item fl">
              <router-link class="link" to="/record">
                <img src="../assets/images/enter4.png" alt="" />
                <div class="word">{{ $i18n.t("my_Flow_Record") }}</div>
              </router-link>
            </div>
            <div class="enter-item fl">
              <router-link class="link" to="/invate">
                <img src="../assets/images/enter5.png" alt="" />
                <div class="word">{{ $i18n.t("my_Invite_friends") }}</div>
              </router-link>
            </div>
            <div class="enter-item fl">
              <router-link class="link" to="/help">
                <img src="../assets/images/enter6.png" alt="" />
                <div class="word">{{ $i18n.t("my_Help") }}</div>
              </router-link>
            </div>
            <!-- <div class="enter-item fl">
              <a href="###" class="link">
                <img src="../assets/images/enter7.png" alt="" />
                <div class="word">关于我们</div>
              </a>
            </div> -->
            <!-- <div class="enter-item fl">
              <a href="###" class="link">
                <img src="../assets/images/enter8.png" alt="" />
                <div class="word">断开连接</div>
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <tab-bar :currentIndex="currentTab" @changeTab="changeTab" />
  </div>
</template>

<script>
import TabBar from "@/components/TabBar.vue";
function getFullNum(num){
    //处理非数字
    if(isNaN(num)){return num};
    //处理不需要转换的数字
    var str = ''+num;
    if(!/e/i.test(str)){return num;};
    return (num).toFixed(18).replace(/\.?0+$/, "");
}
export default {
  data() {
    return {
      orderCount: {},
      userInfo: {
        user: {
          address: "",
        },
        calcFrozenEth: 0,
      },
      currentTab: 4,
      // systemConfig: {
      //   appName: '',
      //   appLogo: ''
      // }
      // TotalReturnsETH => 总收益，TodayReturnsETH => 当日收益
    };
  },
  components: {
    TabBar,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      // return value.charAt(0).toUpperCase() + value.slice(1)
      return value.substring(0, 6) + "*******" + value.substring(value.length - 6);
    },
    fixed_6: function (value) {
      if (!value && value !== 0) return 0;
      return value.toFixed(6);
    },
    substr6(val) {
      if (!val && val !== 0) return 0;
      val = typeof val === 'number' ? `${getFullNum(val)}` : val
      const [f, n] = val.split('.')
      return val == '0' ? 0 : `${f}${n ? `.${n.substr(0, 6)}` : ''}`
    }
  },
  computed: {
    systemConfig() {
      return this.$store.state.user.sysConfig
    }
  },
  created() {
    this.getUserInfo();
    this.getOrderCount();
  },
  methods: {
    substr61(val) {
      if (!val && val !== 0) return 0;
      val = typeof val === 'number' ? `${getFullNum(val)}` : val
      const [f, n] = val.split('.')
      return val == '0' ? 0 : `${f}${n ? `.${n.substr(0, 6)}` : ''}`
    },
    _fixed(val) {
      if (!val) {
        return '0'
      }
      const _val = typeof val === 'string' ? val : `${val}`
      if (_val === '0') {
        return '0'
      }
      const [f, n] = _val.split('.')
      if (+n === 0) {
        return f
      } else {
        return this.substr61(+_val)
      }
    },
    changeTab(index) {
      this.currentTab = index;
    },
    toOrder(status) {
      this.$router.push("/order?status=" + status);
    },
    getUserInfo() {
      let that = this;
      this.$http.getEthPrice().then((_res) => {
        if (_res.code === 0) {
          const price = _res.data.ethPrice.price;
          this.$http.getUserInfo().then((res) => {
            console.log(res);
            if (res.code === 0) {
              that.userInfo = res.data;
              that.userInfo.calcFrozenEth = that.substr61(
                res.data.frozen / price +
                res.data.unsettledETH
              );
            }
          });
        }
      });
    },
    getOrderCount() {
      let that = this;
      this.$http.getOrderCount().then((res) => {
        console.log(res);
        if (res.code === 0) {
          that.orderCount = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.mine {
  overflow-x: hidden;
  padding: 56px 0 2rem 0;
  background: #f9f9f9;
  .content {
    .my-info {
      padding: 0.48rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .info-box {
        display: flex;
        height: 0.9rem;
        .avatar {
          width: 0.8rem;
          height: 0.8rem;
          overflow: hidden;
          background: #fff;
          border-radius: 100%;
          margin-right: 0.16rem;
          margin-top: 0.05rem;
          img {
            width: 0.8rem;
            height: 0.8rem;
          }
        }
        .avatar-right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          .name {
            line-height: 0.4rem;
            margin-bottom: 0.1rem;
            text-align: left;
          }
          .level {
            min-width: 1.6rem;
            padding: 1px 4px;
            border-radius: 0.4rem;
            display: flex;
            align-items: center;
            border: 1px solid #999;
            img {
              width: 0.4rem;
              height: 0.34rem;
              // margin-top: 0.03rem;
            }
            .word {
              // line-height: 0.4rem;
              color: #999;
            }
          }
          .level.border-gradient {
            border: 1px solid transparent;
          }
        }
      }
      .to-upgrade {
        height: 0.4rem;
        border-radius: 0.4rem;
        line-height: 0.36rem;
        display: flex;
        color: #333;
        border: 1px solid #333;
        // margin-top: 0.25rem;
        .word {
          transform: scale(0.87);
        }
        img {
          width: 0.28rem;
          height: 0.28rem;
          margin-top: 0.05rem;
          margin-left: -5px;
        }
      }
    }
    .my-level {
      background: #fff;
      padding: 0.3rem 0.48rem;
      display: flex;
      margin-bottom: 0.3rem;
      .level-item {
        width: 50%;
        text-align: left;
        line-height: 0.4rem;
        .word {
          margin-bottom: 0.12rem;
        }
      }
    }
    .money-block {
      padding-bottom: 0.3rem;
      background: #fff;
      margin-bottom: 0.3rem;
      .mine-block {
        padding-bottom: 0.3rem;
        border-bottom: 1px solid #f5f5f5;
      }
      .mine-block:last-child {
        border-bottom: none;
      }
    }
    .order-block {
      margin-bottom: 0.3rem;
    }
    .enter-block {
      .mine-block {
        .title {
          margin-bottom: 0.1rem;
        }
      }
    }
    .mine-block {
      background: #fff;
      padding: 0.3rem 0;
      .title {
        text-align: left;
        line-height: 0.6rem;
        margin-bottom: 0.3rem;
        padding: 0 0.48rem;
        display: flex;
        justify-content: space-between;
      }
      .data-block {
        display: flex;
        .data-item {
          width: 33.3%;
          display: flex;
          flex-direction: column;
          .word {
            margin-bottom: 0.15rem;
            text-align: center;
            flex: 1;
          }
          .price {
            display: flex;
            // justify-content: center;
            align-items: center;
            padding: 0 0.48rem;
            img {
              flex-shrink: 0;
            }
            .num {
              flex: 1;
              text-align: center;
            }
          }
        }
      }
      .enter-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .enter-item {
          width: 25%;
          margin-top: 0.3rem;
          a.link {
            display: block;
            img {
              width: 0.48rem;
              height: 0.48rem;
              margin-bottom: 0.12rem;
            }
            .word {
              color: #333;
              font-size: 0.28rem;
              line-height: 0.4rem;
            }
          }
        }
      }
    }
  }
}
</style>
